<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t('backoffice.settings.subtitle-emails') }}</h4>
      <div class="d-flex">
        <b-button variant="primary" @click="addEmailCouple()">
          <feather-icon
            icon="PlusCircleIcon"
            class="text-white"
          />
        </b-button>
        <b-button variant="primary" class="ml-1" @click="saveEmails()">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>

    <div v-if="isFirstEmailSectionVisible" class="d-flex flex-wrap">
      <!-- Name -->
      <b-form-group class="flex-grow-1">
        <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="email-name"
          v-model="currentEmail.label"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Email -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.email-label") }}</label>
        <b-form-input
          id="email-name"
          v-model="currentEmail.email"
          type="email"
          :state="isValid"
          @input="validator(currentEmail.email)"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </div>
    <!-- All emails -->
    <div v-for="(email, index) in emails" :key="index" class="d-flex flex-wrap align-items-center">
      <!-- Name -->
      <b-form-group class="flex-grow-1">
        <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="email-name"
          v-model="emails[index].label"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Email -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.email-label") }}</label>
        <b-form-input
          id="email-name"
          v-model="emails[index].email"
          type="email"
          @input="validator(emails[index].email)"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <feather-icon icon="Trash2Icon" size="16" class="ml-50 text-primary pointer" @click="removeItem(index)"/>
    </div>
  </b-card>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { validatorEmailValidator } from '@/@core/utils/validations/validators';

export default {
  name: 'Emails',
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentEmail: {
        label: null,
        email: null,
      },
      emails: [],
      isValid: null,
      isFirstEmailSectionVisible: false,
      isRemoving: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isSaveEnabled() {
      return this.form.name && this.form.slug && (this.form.accessibilityAux || this.form.accessibility) && (this.form.visibilityAux || this.form.visibility);
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  created() {
    this.emails = this.currentCollective.emails;
    // console.log('this.currentCollective', this.currentCollective)
    if (this.emails?.length > 0) {
      this.isFirstEmailSectionVisible = false;
    } else {
      this.isFirstEmailSectionVisible = true;
    }
  },
  methods: {
    addEmailCouple() {
      if(!this.emails){
        this.emails = [];
      }
      if (this.currentEmail.email && this.currentEmail.email !== null && validatorEmailValidator(this.currentEmail.email)) {
        this.emails.push(this.currentEmail);
        this.currentEmail = {};
        this.isValid = null;
      } else if (this.isRemoving) {
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstEmailSectionVisible = true;
    },
    async saveEmails() {
      this.addEmailCouple();
      this.emails = this.emails.filter(({ email }) => email !== '');
      this.emails?.length > 0 ? this.isFirstEmailSectionVisible = false : true;
      this.currentEmail = {};
      const address = this.emails.map(({ email, label }) => ({ email, label }));
      if (address?.length > 0 || this.isRemoving) {
        try {
          await this.$store.dispatch('addSettings', { emails: address });
          this.notifySuccess(this.$t('backoffice.settings.messages.success'));
        } catch {
          this.notifyError(this.$t('backoffice.settings.messages.error'));
        }
      } else {
        this.notifyError(this.$t('backoffice.settings.messages.error'));
      }
      this.isRemoving = false;
    },
    validator(item) {
      this.isValid = validatorEmailValidator(item);
    },
    removeItem(index) {
      this.emails.splice(index, 1);
      this.isRemoving = true;
      if (this.emails?.length <= 0) {
        this.addEmailCouple();
      }
    },
  },
};
</script>
